<template>
  <div class="form-group form-group-sm">
    <label v-if="label" :for="'default-tab-' + id" data-testid="label"
      >{{ label }} <Tooltip v-if="hint" :title="hint"
    /></label>
    <div :class="{'input-group': canCreateTab}">
      <select
        class="form-control"
        v-model="tab"
        :id="'default-tab-' + id"
        data-testid="select-tab"
        :disabled="context == 'user'"
      >
        <option
          v-for="tab in filteredTabs"
          :value="tab.name"
          :key="tab.name"
          data-testid="tab"
        >
          {{ $tc(tab.title, 2) }}
        </option>
        <optgroup
          v-if="context == 'user' && hasContractTabs"
          :label="$t('contract_tabs')"
        >
          <option
            v-for="tab in contractTabs"
            :value="tab.name"
            :key="tab.name"
            data-testid="tab"
          >
            {{ $tc(tab.title, 2) }}
          </option>
        </optgroup>
      </select>
      <div class="input-group-btn">
        <button
          class="btn btn-sm btn-danger"
          :title="`${$t('remove')} ${selectedTab.title}`"
          @click="$emit('tab:remove', tab)"
          data-testid="remove"
          v-if="canRemoveSelectedTab"
        >
          <i class="fa fa-remove"></i>
        </button>
        <button
          class="btn btn-sm btn-default"
          :title="$t('reset_default_tab', {tab: contractDefaultTab})"
          @click="resetTab"
          data-testid="reset"
          data-toggle="tooltip"
          ref="reset"
          v-if="reset && context != 'user'"
        >
          <i class="fa fa-undo"></i>
        </button>
        <button
          class="btn btn-sm btn-default"
          :title="$t('create_new_tab.title')"
          @click="createTabModalOpen = true"
          data-testid="create"
          v-if="canCreateTab"
        >
          <i class="fa fa-plus"></i>
        </button>
      </div>
    </div>
    <portal to="modal" v-if="canCreateTab">
      <CreateDashboardTabModal
        :context="context"
        :open.sync="createTabModalOpen"
      />
    </portal>
  </div>
</template>

<script>
import CreateDashboardTabModal from "@/components/create-dashboard-tab-modal";
import Tooltip from "@/components/tooltip.vue";
import {mapGetters} from "vuex";
export default {
  name: "DashboardTab",
  components: {
    CreateDashboardTabModal,
    Tooltip
  },
  props: {
    label: {
      type: String,
      default: ""
    },
    hint: {
      type: String,
      default: ""
    },
    tabs: {
      type: Array,
      required: true
    },
    value: {
      type: String,
      default: "station-report"
    },
    context: {
      type: String,
      default: "user"
    },
    reset: {
      type: Boolean,
      default: false
    },
    canRemove: {
      type: Function,
      default: () => true
    }
  },
  data() {
    return {
      createTabModalOpen: false,
      id: this.$utils.uuid()
    };
  },
  watch: {
    reset: {
      immediate: true,
      handler(reset) {
        if (reset) {
          this.$nextTick(() =>
            $(this.$refs.reset).tooltip({
              delay: {show: 300}
            })
          );
        }
      }
    }
  },
  computed: {
    canCreateTab() {
      return (
        this.context != "user" &&
        this.$store.getters["user/loggedUser"].contract
          ?.allowed_custom_screens &&
        this.$can("manage", "TelaCustomizadaAcesso")
      );
    },
    canRemoveSelectedTab() {
      return this.selectedTab?.screenId && this.canRemove(this.selectedTab);
    },
    selectedTab() {
      return this.tabs.find((t) => t.name == this.tab);
    },
    tab: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    },
    contractDefaultTab() {
      const tab = this.tabs.find(
        (t) =>
          t.name ==
          (this.contract?.portal_data?.defaultDashboardTab ??
            this.$root.config.equipment_selection.tabs.initial)
      );
      return this.$tc(tab?.title, 2);
    },
    filteredTabs() {
      return this.context == "user"
        ? this.tabs.filter((t) => !this.isContractTab(t))
        : this.tabs;
    },
    contractTabs() {
      return this.tabs.filter(this.isContractTab);
    },
    hasContractTabs() {
      return this.contractTabs.length > 0;
    },
    ...mapGetters("user", ["contract"])
  },
  methods: {
    resetTab() {
      $(this.$refs.reset).tooltip("destroy");
      this.$emit("reset");
    },
    isContractTab(tab) {
      return tab.name.startsWith("@");
    }
  }
};
</script>

<style scoped>
select.form-control[disabled] {
  -webkit-appearance: none;
  appearance: none;
  padding: 0 10px;
}
</style>
