<template>
  <section>
    <form role="form">
      <div class="row">
        <div class="col-xs-6">
          <label class="noselect" :class="{'disabled': !canManageSupportUser}">
            {{ $t("titles.authorize_support_user") }}?
            <ToolTip :title="$t('hints.authorize_support_user')" />
          </label>
          &nbsp;
          <FormInputToggle
            :labels="$t('yes') + ',' + $t('no')"
            v-model="isSupportUserAuthorized"
            :disabled="busy || !canManageSupportUser"
          ></FormInputToggle>
          <i class="fa fa-refresh fa-2x fa-spin" v-if="busy"></i>
        </div>
      </div>
    </form>
  </section>
</template>

<script>
import ToolTip from "@/components/tooltip.vue";
import FormInputToggle from "@/components/registration/form-input-toggle.vue";
import MixinAlert from "@/project/mixin-alert.js";
import UserService from "@/services/user.js";

export default {
  name: "FormMaintenance",
  components: {
    FormInputToggle,
    ToolTip
  },
  mixins: [MixinAlert],
  data() {
    return {
      busy: true,
      support_user_is_member: false,
      service: null
    };
  },
  computed: {
    contract() {
      return this.$store.getters["user/contract"] || null;
    },
    user() {
      return this.$store.getters["user/loggedUser"]
    },
    users() {
      return this.$store.getters["user/users"] || [];
    },
    systemProcessArea() {
      return (this.$store.getters["processArea/processAreas"] || []).find(
        (process_area) => process_area.is_system
      );
    },
    systemUserGroup() {
      return (this.$store.getters["role/roles"] || []).find(
        (user_group) => user_group.is_system
      );
    },
    supportUserEmail() {
      return this?.$root?.config?.references?.support_user_email;
    },
    supportUser() {
      return this.users.find((user) => user.email == this.supportUserEmail);
    },
    hasSupportUserContractAccess() {
      return this.users.some((user) => 
        user.email == this.supportUserEmail &&
        (user.allowed_permissions || []).find(
          (permission) => (permission.process_areas || []).find(
            ({id}) => id == this.systemProcessArea.id
          )
        )
      );
    },
    isSupportUserAuthorized: {
      set(value) {
        this.handleSupportUser(value == 1);
      },
      get() {
        return this.support_user_is_member
      }
    },
    config() {
      return {
        support_user_is_member: this.support_user_is_member
      }
    },
    canManageSupportUser() {
      return (
        this?.supportUserEmail &&
        this?.systemProcessArea?.id &&
        this?.systemUserGroup?.id &&
        this?.user?.id != this?.supportUser?.id
      );
    }
  },
  methods: {
    addSupportUser() {
      return new Promise((resolve, reject) => {
        // Adiciona o acesso às áreas de processo de sistema desse Contrato
        let payload = {
          contract_id: this.contract.id,
          email: this.supportUserEmail,
          associated_permissions: [{
            process_area_id: this.systemProcessArea.id,
            user_groups_ids: [this.systemUserGroup.id]
          }]
        };
        // Se o usuário já é membro do Contrato
        if (this.supportUser?.id) {
          payload.id = this.supportUser.id;
          payload.etag = this.supportUser.etag;

          // Mantém as permissões atuais
          (this.supportUser.allowed_permissions || []).forEach(
            (permission) => {
              (permission.process_areas || []).forEach(
                (process_area) => {
                  payload.associated_permissions.push({
                    process_area_id: process_area.id,
                    user_groups_ids: 
                      (process_area.user_groups || []).map(({id}) => id)
                  });
                }
              )
            }
          ); 
        }
        // Verifica permissões e confirma as alterações de permissão
        this.validatePrivileges("manage", "MembroDeContratoEscrita").then(
          (result) => {
            if (!result) {
              console.log('err');
              reject()
              return;
            }
            this.service
              .save(payload)
              .then((result) => {
                if (!result) reject();
                this.fetchUsers();
                resolve(true);
              })
              .catch((error) => {
                this.$swal({
                  title: this.$t("item_could_not_be_saved"),
                  text: error,
                  icon: "error"
                });
                reject(error);
              });
          });
      });
    },
    removeSupportUser() {
      return new Promise((resolve, reject) => {
        if (!this.supportUser?.id) {
          this.fetchUsers();
          resolve(true);
          return;
        }
        const payload = {
          contract_id: this.contract.id,
          id: this.supportUser.id,
          etag: this.supportUser.etag
        };
        // Verifica permissões e confirma as alterações de permissão
        this.validatePrivileges("manage", "MembroDeContratoEscrita").then(
          (result) => {
            if (!result) {
              reject()
              return;
            }
            this.service
              .remove(payload)
              .then((result) => {
                if (!result) reject();
                this.fetchUsers();
                resolve(true);
              })
              .catch((error) => {
                this.$swal({
                  title: this.$t("item_could_not_be_saved"),
                  text: error,
                  icon: "error"
                });
                reject(error);
              });
          });
      });
    },
    handleSupportUser(authorize) {
      this.busy = true;
      return new Promise((resolve, reject) => {
        const func = authorize ? this.addSupportUser : this.removeSupportUser;
        func()
          .then(() => {
            this.support_user_is_member = authorize;
            this.$emit("changed", this.config);
            this.busy = false;
            resolve(true);
          })
          .catch((error) => {
            this.busy = false;
            reject(error)
          });
      });
    },
    fetchUsers() {
      return this.$store.dispatch("user/fetchUsers", true);
    },
    fetchRoles() {
      return this.$store.dispatch("role/fetchRoles");
    },
    fetchProcessAreas() {
      return this.$store.dispatch("processArea/fetchProcessAreas");
    },
    setup() {
      // Verifica se já foi configurado como autorizado
      this.support_user_is_member =
        this.contract?.portal_data?.support_user_is_member
        ? true : false;

      // Caso esteja autorizado e não tenha acesso ainda e vice-versa
      if (
        this.support_user_is_member !=
        this.hasSupportUserContractAccess
      ) {
        this.support_user_is_member = this.hasSupportUserContractAccess;
        this.$emit("changed", this.config);
      }

      this.busy = false;
    }
  },
  mounted() {
    // Caso já tenha carregado as configurações
    if (
      this.supportUser?.id &&
      this.systemProcessArea?.id &&
      this.systemUserGroup?.id
    ) {
      this.setup();
      return;
    }
    // Caso contrário, arrega a lista de usuários e depois realiza a
    // configuração
    Promise.all([
      this.fetchUsers(),
      this.fetchProcessAreas(),
      this.fetchRoles()
    ]).then(
      () => this.setup()
    );
  },
  created() {
    this.service = new UserService();
  }
};
</script>

<style scoped>
label.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
</style>
