<template>
  <div class="nav-tabs-custom">
    <ul class="nav nav-tabs nav-justified hidden-xs">
      <li class="active">
        <a href="#tab_1" data-toggle="tab">
          <i class="fa fa-info-circle"></i> {{ $t("general") }}
        </a>
      </li>
      <li>
        <a href="#tab_2" data-toggle="tab">
          <i class="fa fa-send"></i>
          {{ $tc("notification", 2) }}
        </a>
      </li>
      <li>
        <a href="#tab_3" data-toggle="tab">
          <i class="fa fa-tags"></i>
          {{ $tc("text_list", 2) }}
        </a>
      </li>
      <li>
        <a href="#tab_4" data-toggle="tab">
          <i class="fa fa-cogs"></i>
          {{ $t("maintenance") }}
        </a>
      </li>
    </ul>
    <ul class="nav nav-tabs hidden-lg hidden-md hidden-sm">
      <li class="active">
        <a href="#tab_1" data-toggle="tab">
          <i class="fa fa-info-circle"></i> {{ $t("general") }}
        </a>
      </li>
      <li>
        <a href="#tab_2" data-toggle="tab">
          <i class="fa fa-send"></i>
          {{ $tc("notification", 2) }}
        </a>
      </li>
      <li>
        <a href="#tab_3" data-toggle="tab">
          <i class="fa fa-tags"></i>
          {{ $tc("text_list", 2) }}
        </a>
      </li>
    </ul>
    <div class="tab-content">
      <div class="tab-pane active" id="tab_1">
        <div>
          <div class="row">
            <div class="col-sm-6 col-xs-12">
              <div class="form-group">
                <label for="contract_name">
                  {{ $t("contract_name") }}
                  <Tooltip :title="$t('contract_name_hint')" />
                </label>
                <input
                  type="text"
                  class="form-control"
                  name="contract_name"
                  id="contract_name"
                  v-model="contractName"
                />
              </div>
            </div>
          </div>

          <template v-if="portalData">
            <div class="row">
              <div class="col-sm-6 col-xs-12">
                <div v-if="removingTabs.length">
                  <strong class="text-danger"
                    >{{ $t("removing_tabs") }}
                    <span
                      class="btn btn-danger btn-xs"
                      v-for="(tab, index) in removingTabs"
                      :key="tab.name"
                      :title="$t('cancel')"
                      @click="removeTab(index)"
                      style="margin: 1px"
                      data-testid="removing-tab"
                      >{{ tab.title }} <span class="fa fa-remove"></span></span
                  ></strong>
                  <em
                    class="text-warning"
                    style="
                      display: block;
                      margin-top: 0.5em;
                      margin-bottom: 1em;
                    "
                    >{{
                      $t("changes_will_be_performed_after_saving", {
                        save: $t("save")
                      })
                    }}</em
                  >
                </div>
                <div style="display:flex;justify-content:space-between">
                  <label for="default-tab" data-testid="label">
                    {{ $t("default_dashboard_for_contract") }}
                    <Tooltip
                      :title="$t('titles.default_dashboard_for_contract')"
                    />
                  </label>
                  <i
                    class="fa fa-sliders text-info text-bold text-primary "
                  ></i>
                </div>
                <CustomTabsForm
                  :key="cid"
                  v-model="customPortalData"
                  @restore="customPortalData = null"
                >
                </CustomTabsForm>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6 col-xs-12">
                <PoolingIntervalSelector
                  v-model="manualRefreshTimeout"
                  :list="manualRefreshIntervalList"
                  label="manual_pooling_interval"
                  tooltip="manual_pooling_interval_ui"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6 col-xs-12">
                <div class="form-group">
                  <label>
                    {{ $t("titles.default_contract_theme") }}
                    <Tooltip :title="$t('hints.default_contract_theme')" />
                  </label>
                  <select v-model="contractCSSTheme" class="form-control">
                    <option value="">{{ $tc("none", 1) }}</option>
                    <option value="skin-black-light">{{
                      $t("default_theme")
                    }}</option>
                    <option value="skin-dark">{{ $t("dark_theme") }}</option>
                  </select>
                </div>
              </div>
            </div>
            <BaseButton
              type="primary"
              @click="save"
              :disabled="loading"
              data-testid="save"
            >
              {{ loading ? $t("saving") + "..." : $t("save") }}
            </BaseButton>
          </template>
        </div>
      </div>
      <div class="tab-pane" id="tab_2">
        <FormConfigNotification
          :defaults="{
            channel: 'email',
            subject: 'data_history_usage_notifications'
          }"
          :notification_channels="notification_channels"
          v-model="groups"
          style="margin-top: 2rem"
          :enabled="true"
        />
        <BaseButton
          type="primary"
          @click="save"
          :disabled="loading"
          data-testid="save"
        >
          {{ loading ? $t("saving") + "..." : $t("save") }}
        </BaseButton>
      </div>
      <div class="tab-pane" id="tab_3">
        <div>
          <label for=""
            >{{ $t("list") }}
            <Tooltip :title="$t('hints.text_list')" />
          </label>
        </div>
        <FormTextListInline />
      </div>
      <div class="tab-pane" id="tab_4">
        <FormMaintenance @changed="formMaintenanceChanged" />
      </div>
    </div>
  </div>
</template>

<script>
import {uniqBy} from "lodash";
import ContractService from "@/services/contract.js";
import Tooltip from "@/components/tooltip";
import BaseButton from "@/components/base/buttons/base-button";
import PoolingIntervalSelector from "@/components/pooling-interval-selector.vue";
import MixinRuleValidation from "@/project/mixin-rule-validation.js";
import MixinAlert from "@/project/mixin-alert.js";
import FormTextListInline from "@/components/registration/form-text-list-inline.vue";
import FormConfigNotification from "@/components/registration/form-config-notification.vue";
import FormMaintenance from "@/components/registration/form-maintenance.vue";
import CustomTabsForm from "@/components/control-sidebar/property-editors/custom-tabs-form.vue";
import messages from "@/i18n/data";
export default {
  name: "ContractSettings",
  mixins: [MixinRuleValidation, MixinAlert],
  i18n: {messages},
  components: {
    BaseButton,
    PoolingIntervalSelector,
    Tooltip,
    FormTextListInline,
    FormConfigNotification,
    FormMaintenance,
    CustomTabsForm
  },
  data() {
    return {
      contractName: "",
      portalData: {},
      removingTabs: [],
      loading: false,
      groups: null,
      cid: 0
    };
  },
  computed: {
    contract() {
      return this.$store.getters["user/contract"] || {};
    },
    defaultPortalData() {
      return {
        defaultDashboardTab: this.$root.config.equipment_selection.tabs.initial
      };
    },
    dashboardTabs() {
      return (this.$root.config.equipment_selection.tabs.items || [])
        .filter((t) => t.enabled && (!t.rule || this.hasPermission(t.rule)))
        .concat(this.portalData?.userTabs || []);
    },
    selectedTab() {
      return this.dashboardTabs.find(
        (t) => t.name == this.portalData.defaultDashboardTab
      );
    },
    manualRefreshTimeout: {
      set(value) {
        this.$set(this.portalData, "manualRefreshTimeout", parseInt(value));
      },
      get() {
        return this?.portalData?.manualRefreshTimeout || 10000;
      }
    },
    contractCSSTheme: {
      set(value) {
        this.$set(this.portalData, "contract_css_theme", value);
      },
      get() {
        return this?.portalData?.contract_css_theme || "";
      }
    },
    manualRefreshIntervalList() {
      return [
        {value: 10000, label: "10s"},
        {value: 30000, label: "30s"},
        {value: 60000, label: "1min"},
        {value: 150000, label: "2.5 min"},
        {value: 300000, label: "5 min"},
        {value: 600000, label: "10 min"},
        {value: 900000, label: "15 min"}
      ];
    },
    notification_channels() {
      // TODO: make it configurable:
      let lst = [
        {
          channel: "email",
          icon: "fa fa-envelope-o",
          enabled: true,
          placeholder: "email",
          rule: "",
          data_source: {
            data_history_usage_notifications: {
              attr: "emails",
              title: "Usuarios à notificar por email",
              text: "hints.data_history_usage_notifications",
              short: "tab.data_history_usage_notifications",
              icon: "glyphicon glyphicon-stats "
            }
          }
        }
      ];
      let contract = this.contract || null;
      return lst
        .filter(
          (channel) =>
            (channel.enabled && !channel.rule) ||
            !contract ||
            (channel.rule in contract && contract[channel.rule]) ||
            false
        )
        .map((item) => {
          return {...item, name: item.channel};
        });
    },
    payload_notifications() {
      //notifications
      //https://hitecnologia.bitrix24.com.br/extranet/workgroups/group/47/tasks/task/view/16886/
      let self = this;
      let payload = {};
      if (self.groups && self.groups.length) {
        for (var i in self.groups) {
          var group = self.groups[i];
          payload[group.data_source] =
            group.data_source in payload ? payload[group.data_source] : {};
          payload[group.data_source][group.attr] = group.tags || [];
        }
      }
      return payload;
    },
    customPortalData: {
      set(value) {
        if (value) {
          this.portalData.defaultDashboardTab = value.defaultDashboardTab;
          let lst = structuredClone(value.userTabs);
          if (!this.portalData.customTabs) {
            // lst = lst.reverse();
          }
          this.$set(this.portalData, "customTabs", uniqBy(lst, "name"));
          this.cid++;
        } else {
          this.$utils
            .confirm(this, "you_really_want_to_restore", "are_you_sure")
            .then((ok) => {
              if (!ok) return;
              this.portalData.defaultDashboardTab = this?.$root?.config?.equipment_selection?.tabs.initial;
              this.$set(this.portalData, "customTabs", null);
              this.cid++;
              this.save();
            });
        }
      },
      get() {
        if (!this.portalData) return;
        let defaultDashboardTab =
          this.portalData.defaultDashboardTab ||
          this?.$root?.config?.equipment_selection?.tabs?.initial;
        let lst = [];
        if (this.portalData.customTabs) {
          lst = this.portalData.customTabs || [];
        } else {
          lst = (
            this?.$root?.config?.equipment_selection?.tabs?.items || []
          ).filter((t) => t.enabled && (!t.rule || this.hasPermission(t.rule)));
          lst = lst.reverse();
        }
        return {
          defaultDashboardTab: defaultDashboardTab,
          userTabs: uniqBy(lst, "name")
        };
      }
    }
  },
  watch: {
    "contract.portal_data": {
      immediate: true,
      deep: true,
      handler(n) {
        this.portalData = n ? JSON.parse(JSON.stringify(n)) : {};
        // backward compatibility (previous version make use of userTabs)
        if (!n.customTabs && n?.userTabs?.length) {
          let lst = (
            this?.$root?.config?.equipment_selection?.tabs?.items || []
          )
            .filter((t) => t.enabled && (!t.rule || this.hasPermission(t.rule)))
            .concat(n.userTabs || []);
          this.customPortalData = {
            defaultDashboardTab: this?.$root?.config?.equipment_selection?.tabs
              .initial,
            userTabs: lst
          };
          delete this.portalData.userTabs;
        }
      }
    }
  },
  methods: {
    removeTab(index) {
      if (typeof index == "number") {
        // cancel removing of tab in given index
        this.portalData.userTabs.push(this.removingTabs.splice(index, 1)[0]);
      } else {
        // add selected tab to removing list
        this.removingTabs.push(this.selectedTab);
        // reset default tab to portal default
        this.$nextTick(() => {
          this.portalData.defaultDashboardTab = this.$root.config.equipment_selection.tabs.initial;
        });
        // removes tab from portal data
        this.portalData.userTabs.splice(
          this.portalData.userTabs.findIndex(
            (t) => t.name == this.selectedTab.name
          ),
          1
        );
      }
    },
    formMaintenanceChanged(values) {
      this.portalData.support_user_is_member = values.support_user_is_member;
      this.save();
    },
    save() {
      if (this.loading) return;
      let payload = {
        id: this.contract.id,
        etag: this.contract.etag,
        portal_data: this.portalData,
        name: this.contractName,
        notification_contacts: this.payload_notifications
      };
      this.loading = true;
      this.service
        .updateContract(payload)
        .then((response) => {
          this.loading = false;
          if (this.validateSaveResponse(response)) {
            this.$store.dispatch("user/configureUserContract"); // config contract
            this.$emit("save", response);
            this.removingTabs = [];
          }
          this.showAlert();
        })
        .catch((e) => {
          this.loading = false;
        });
    },
    initGroups() {
      var groups = [
        {
          channel: "email",
          icon: "fa fa-envelope-o",
          enabled: true,
          placeholder: "email",
          rule: "",
          data_source: "data_history_usage_notifications",
          name: "Email de desconexão",
          fixed: true,
          tags:
            this.contract?.notification_contacts
              ?.data_history_usage_notifications?.emails ?? [],
          tag_validation: {
            classes: "no-email",
            rule:
              '^(([^<>()\\[\\]\\.,;:\\s@\\"]+(\\.[^<>()\\[\\]\\.,;:\\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\\]\\.,;:\\s@\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\"]{2,})$'
          },
          attr: "emails",
          tag_input_label: "Usuarios à notificar por email"
        }
      ];
      this.$set(this, "groups", groups);
    }
  },
  created() {
    this.service = new ContractService();
    this.contractName = this.contract.name;
    this.initGroups();
  }
};
</script>

<style></style>
