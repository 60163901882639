<template>
  <section>
    <div class="row plan-list" v-if="plans">
      <div class="hidden-xs col-md-3 col-lg-3" v-if="showEmptySpace"></div>
      <div :class="infoBoxClasses" v-for="(plan, iplan) in plans" :key="iplan" :data-testid="plan.name">
        <UserPlanInfoBox
          :iplan="iplan"
          :plan="plan"
          :selectedPlanId="selectedPlanId"
          :selectedCommitment="selectedCommitment"
          :selectedDataRange="selectedDataRange"
          :canDeal="canDeal"
          :price="price"
          :markContractedPlan="false"
          @plan-selected="planSelected"
        />
      </div>
    </div>
  </section>
</template>

<script>
import UserPlanInfoBox from "@/components/user-plan/user-plan-info-box.vue";

export default {
  name: "UserPlanList",
  components: {
    UserPlanInfoBox
  },
  props: {
    plans: {
      type: Array,
      required: false,
      default: () => []
    },
    selectedPlanId: {
      type: Number,
      required: true
    },
    selectedCommitment: {
      type: String,
      required: true
    },
    selectedDataRange: {
      type: Number,
      required: true
    },
    canDeal: {
      type: Function,
      required: true
    },
    price: {
      type: Function,
      required: true
    },
    markContractedPlan: {
      type: Boolean,
      required: false,
      default: () => {return true}
    }
  },
  computed: {
    showEmptySpace() {
      return this?.plans?.length <= 1;
    },
    infoBoxClasses() {
      if (this?.plans?.length <= 2) return "col-xs-12 col-md-6 col-lg-6";
      else return "col-xs-12 col-md-6 col-lg-3"
    }
  },
  methods: {
    planSelected(iplan) {
      this.$emit("plan-selected", iplan);
    }
  }
};
</script>

<style>
.plan-list hr {
  margin: 0px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.plan-list img {
  height: 50px;
  margin-bottom: 10px;
}

.plan-list .box-body {
  min-height: 320px;
}

.plan-list .plan-description {
  min-height: 40px;
}

.plan-list .box-footer {
  min-height: 120px;
}

.skin-dark .plan-list .box-footer {
  background-color: var(--skin-dark-darker);
}

@media (max-width: 1720px) {
  .plan-list .plan-description {
    min-height: 60px;
  }
}

@media (max-width: 1200px) {
  .plan-list .box-body {
    min-height: 420px;
  }
  .plan-list .plan-description {
    min-height: 100px;
  }
}
@media (max-width: 992px) {
  .plan-list .box-body {
    min-height: auto;
  }

  .plan-list .plan-description {
    min-height: auto;
  }
}
</style>
