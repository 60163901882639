<template>
  <PortalData
    v-if="portalData"
    v-model="portalData"
    :default="defaultPortalData"
    v-slot="{portal_data}"
  >
    <div class="row">
      <div :class="sidebar ? 'col-xs-12' : 'col-lg-4 text-center'">
        <label
          for="timezone"
          class="no-select clicable label-checkbox text-center"
        >
          <input type="checkbox" id="timezone" v-model="autotz" />
          {{ $t("timezone") }} {{ $t("automatic") }}
          <Tooltip :title="$t('hints.user_timezone')" />
        </label>
      </div>
      <div :class="sidebar ? 'col-xs-12' : 'col-lg-8'">
        <input
          v-if="autotz"
          class="form-control timezone-selector text-center"
          type="text"
          :value="defaultTimezone"
          disabled
        />
        <TimeZoneSelectionForm
          v-if="!autotz"
          :value="portal_data.timezone"
          v-model="portal_data.timezone"
          addon=""
          label=""
          class="timezone-selector"
        />
      </div>
    </div>

    <div class="row">
      <div class="section" :class="sidebar ? 'col-xs-12' : 'col-md-6'">
        <DataFormatInput
          class="form-group-sm"
          v-model="datetimeFormat"
          :inputTypeList="['date_time_format']"
        />
      </div>
      <div class="section" :class="sidebar ? 'col-xs-12' : 'col-md-6'">
        <PoolingIntervalSelector
          class="form-group-sm"
          v-model="portal_data.refreshInterval"
          title="pooling_interval"
          :key="portalDataUpdates + 1"
        />
      </div>
    </div>

    <div class="row">
      <div :class="sidebar ? 'col-xs-12' : 'col-md-6'">
        <div style="display:flex;justify-content:space-between">
          <label for="default-tab" data-testid="label">
            {{
              $t(customTabs ? "user_custom_tabs" : "default_dashboard_for_user")
            }}
            <Tooltip
              :title="
                $t(
                  customTabs
                    ? 'titles.user_custom_tabs'
                    : 'titles.default_dashboard_for_user'
                )
              "
            />
          </label>
          <i
            class="fa btn btn-xs"
            :class="{
              'fa-sliders': !customTabs,
              'fa-chevron-down': customTabs && showCustomTabs,
              'fa-chevron-right': customTabs && !showCustomTabs
            }"
            title="hi"
            @click.stop.prevent="
              customTabs
                ? (showCustomTabs = !showCustomTabs)
                : (customTabs = !customTabs)
            "
          ></i>
        </div>
        <template v-if="customPortalData">
          <CustomTabsForm
            v-if="showCustomTabs"
            v-model="customPortalData"
            @restore="customTabs = false"
          >
          </CustomTabsForm>
          <div class="form-group" v-else>
            <div class="form-control disabled" disabled :title="$tc('user', 1)">
              <!-- <i
                class=""
                :class="currentSelectedTab && currentSelectedTab.iconClass"
              ></i> -->
              <span style="margin-left:5px;vertical-align:middle;">
                {{
                  currentSelectedTab &&
                    $tc(currentSelectedTab.title || currentSelectedTab.name, 2)
                }}
              </span>
            </div>
          </div>
        </template>
        <DashboardTab
          v-else
          context="user"
          :value="defaultPortalData.defaultDashboardTab"
          :reset="'defaultDashboardTab' in portalData"
          :tabs="parentTabs"
          :canRemove="() => false"
          @tab:remove="removeTab"
          @reset="resetTab"
        />
        <div v-if="removingTabs.length">
          <strong class="text-danger"
            >{{ $t("removing_tabs") }}
            <span
              class="btn btn-danger btn-xs"
              v-for="(tab, index) in removingTabs"
              :key="tab.name"
              :title="$t('cancel')"
              @click="removeTab(index)"
              style="margin: 1px"
              >{{ tab.title }} <span class="fa fa-remove"></span></span
          ></strong>
          <em class="text-warning" style="display: block; margin-top: 0.5em">{{
            $t("changes_will_be_performed_after_saving", {
              save: $t("save")
            })
          }}</em>
        </div>
      </div>
      <FeatureConfig
        :class="sidebar ? 'col-xs-12' : 'col-md-6'"
        v-model="portal_data.featureConfig"
      />
    </div>
    <div class="row">
      <div :class="sidebar ? 'col-xs-12' : 'col-md-6'">
        <div class="form-group form-group-sm">
          <label>
            {{ $t("titles.menu_behavior") }}
            <Tooltip :title="$t('hints.menu_behavior')" />
          </label>
          <select class="form-control" v-model="menuBehavior">
            <option value="">{{ $t("default") }}</option>
            <option value="hover">{{ $t("show_menu_on_hover") }}</option>
          </select>
        </div>
      </div>

      <div :class="sidebar ? 'col-xs-12' : 'col-md-6'">
        <div class="form-group form-group-sm">
          <label>
            {{ $t("titles.css_theme") }}
            <Tooltip :title="$t('hints.css_theme')" />
          </label>
          <select class="form-control" v-model="css_theme">
            <option value="">{{ $t("default_theme") }}</option>
            <option value="skin-dark">{{ $t("dark_theme") }}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="row clearfix">
      <div class="col-md-12">
        <div class="footer">
          <button
            class="btn btn-default btn-sm pull-left"
            @click.stop.prevent="restore"
          >
            <i class="fa fa-undo"></i>
            {{ $t("restore") }}
          </button>
          <button
            class="btn btn-sm btn-primary pull-right"
            @click.stop.prevent="save"
            :disabled="!isValid || busy"
            data-testid="save"
          >
            <i class="fa fa-check" v-if="!busy"></i>
            <i class="fa fa-refresh fa-spin" v-if="busy"></i>
            {{ $t("save") }}
          </button>
        </div>
      </div>
    </div>
  </PortalData>
</template>

<script>
import MixinAlert from "@/project/mixin-alert.js";
import MixinRuleValidation from "@/project/mixin-rule-validation.js";
import PoolingIntervalSelector from "@/components/pooling-interval-selector.vue";
import DashboardServices from "@/services/dashboard.js";
import TimeZoneSelectionForm from "@/components/time-zone-selection-form.vue";
import Tooltip from "@/components/tooltip.vue";
import DataFormatInput from "@/components/control-sidebar/property-editors/data-format-input.vue";
import PortalData from "@/components/registration/portal-data";
import DashboardTab from "@/components/dashboard-tab";
import FeatureConfig from "@/components/feature-config";
import CustomTabsForm from "@/components/control-sidebar/property-editors/custom-tabs-form.vue";
import {isEqual, uniqBy} from "lodash";

export default {
  name: "UserPreferencesForm",
  props: {
    sidebar: {
      type: Boolean,
      default: true,
      required: false
    }
  },
  components: {
    PoolingIntervalSelector,
    TimeZoneSelectionForm,
    Tooltip,
    DataFormatInput,
    PortalData,
    DashboardTab,
    FeatureConfig,
    CustomTabsForm
  },
  mixins: [MixinAlert, MixinRuleValidation],
  data() {
    return {
      css_theme: "",
      portalData: null,
      autotz: true,
      busy: false,
      removingTabs: [],
      portalDataUpdates: 0,
      showCustomTabs: true
    };
  },
  computed: {
    datetimeFormat: {
      set(value) {
        this.portalData = this.portalData || {};
        this.$set(this.portalData, "datetimeFormat", value);
      },
      get() {
        return this?.portalData?.datetimeFormat || "L LTS";
      }
    },
    defaultTimezone() {
      return Intl.DateTimeFormat().resolvedOptions().timeZone;
    },
    user() {
      return this.$store.getters["user/loggedUser"];
    },
    datetimeSample() {
      return moment().format(this.portalData?.datetimeFormat);
    },
    isDirty() {
      return !isEqual(this.payload, this.user.user_profile.portal_data);
    },
    isValid() {
      return this.portalData?.datetimeFormat != "" &&
        this.refreshInterval != "" &&
        this.isDirty
        ? true
        : false;
    },
    payload() {
      let portalData = JSON.parse(JSON.stringify(this.portalData));
      portalData.timezone = this.autotz ? "" : portalData.timezone;
      portalData.menuBehavior = this.menuBehavior;
      portalData.css_theme = this.css_theme;
      return portalData;
    },
    datetimeOptions() {
      let lst = [
        {
          value: "DD/MM/YYYY HH:mm:ss",
          label: "DD/MM/YYYY HH:mm:ss"
        },
        {
          value: "DD/MM/YY HH:mm:ss",
          label: "DD/MM/YY HH:mm:ss"
        },
        {
          value: "DD/MM/YY HH:mm",
          label: "DD/MM/YY HH:mm"
        },
        {
          value: "MM/DD/YYYY HH:mm:ss",
          label: "MM/DD/YYYY HH:mm:ss"
        },
        {
          value: "MM/DD/YY HH:mm:ss",
          label: "MM/DD/YY HH:mm:ss"
        },
        {
          value: "MM/DD/YY HH:mm",
          label: "MM/DD/YY HH:mm"
        },
        {
          value: "YYYY/MM/DD HH:mm:ss",
          label: "YYYY/MM/DD HH:mm:ss"
        },
        {
          value: "YY/MM/DD HH:mm:ss",
          label: "YY/MM/DD HH:mm:ss"
        },
        {
          value: "YY/MM/DD HH:mm",
          label: "YY/MM/DD HH:mm"
        },
        {
          value: "L LTS",
          label: "L LTS"
        }
      ];
      if (this?.portalData?.datetimeFormat) {
        if (
          !lst.find((item) => item.value == this?.portalData?.datetimeFormat)
        ) {
          lst.push({
            value: this?.portalData?.datetimeFormat,
            label: this?.portalData?.datetimeFormat
          });
        }
      }
      return lst;
    },
    dashboardTabs() {
      return uniqBy(
        (this.$root.config.equipment_selection.tabs.items || [])
          .filter((t) => t.enabled && (!t.rule || this.hasPermission(t.rule)))
          .concat(
            (this?.portalData?.userTabs || []).filter((t) =>
              t.contractId ? t.contractId == this.user.contract_id : true
            )
          )
          .concat(
            (this.contractPortalData?.userTabs || []).filter(
              (t) => !this.isContractTabInUser(t)
            )
          ),
        "name"
      );
    },
    defaultPortalData() {
      return {
        defaultDashboardTab:
          this.contractPortalData?.defaultDashboardTab ??
          this.$root.config.equipment_selection.tabs.initial,
        featureConfig: this.$featureToggle.featureConfig
      };
    },
    selectedTab() {
      return this.parentTabs.find(
        (t) => t.name == this.portalData.defaultDashboardTab
      );
    },
    contractPortalData() {
      return this.user?.contract?.portal_data;
    },
    menuBehavior: {
      set(value) {
        this.portalData = this.portalData || {};
        this.portalData.menuBehavior = value;
      },
      get() {
        return this.portalData?.menuBehavior ?? "";
      }
    },
    customTabs: {
      set(value) {
        if (value && !this?.portalData?.custom_tabs) {
          this.enableCustomTabs();
        } else if (this?.portalData?.custom_tabs && !value) {
          this.$utils
            .confirm(this, "you_really_want_to_restore", "are_you_sure")
            .then((ok) => {
              if (!ok) return;
              this.disableStandardTabs();
            });
        }
      },
      get() {
        return this.portalData?.custom_tabs ?? false;
      }
    },
    customPortalData: {
      set(value) {
        if (!this.customTabs) return;
        // let portalData = this.portalData || {};
        // portalData.userTabs = value.userTabs;
        // portalData.defaultDashboardTab = value.defaultDashboardTab;
        // this.$set(this, "portalData", portalData);
        this.$set(
          this.portalData,
          "userTabs",
          uniqBy([...value.userTabs], "name")
        );
        this.$set(
          this.portalData,
          "defaultDashboardTab",
          value.defaultDashboardTab
        );
      },
      get() {
        let customTabs = this.customTabs
          ? {
              userTabs: uniqBy(this.portalData.userTabs || [], "name"),
              defaultDashboardTab: this.portalData.defaultDashboardTab
            }
          : null;
        if (customTabs && customTabs.userTabs.length) {
          // add the contract ones that were added after customization;
          this.parentTabs.forEach((i) => {
            if (
              !i.enabled ||
              customTabs.userTabs.some(({name}) => name == i.name)
            )
              return;
            customTabs.userTabs.push({...i});
          });
        }
        return customTabs;
      }
    },
    parentTabs() {
      return uniqBy(
        this.contractPortalData.customTabs ||
          (this?.$root?.config?.equipment_selection?.tabs.items || [])
            .filter((t) => t.enabled && (!t.rule || this.hasPermission(t.rule)))
            .concat(this.contractPortalData?.userTabs || []),
        "name"
      );
    },
    currentSelectedTabName() {
      return (
        this.portalData.defaultDashboardTab ||
        (this.contractPortalData?.defaultDashboardTab ??
          this.$root.config.equipment_selection.tabs.initial)
      );
    },
    currentSelectedTab() {
      let lst = (this?.portalData?.userTabs || []).concat(this.parentTabs);
      let item = lst.find(({name}) => name == this.currentSelectedTabName);
      if (item) {
        if (
          /glyphicon/.test(item.iconClass) &&
          !/(\w[\s]|[!\s]\w)/.test(item.iconClass)
        ) {
          item.iconClass = `glyphicon ${item.iconClass}`;
        } else if (
          /fa/.test(item.iconClass) &&
          !/(\w[\s]|[!\s]\w)/.test(item.iconClass)
        ) {
          item.iconClass = `fa ${item.iconClass}`;
        }
      }
      return item;
      // return (item && this.$tc(item.title || item.name, 2)) || "";
    },
    userCSSTheme() {
      return this?.user?.user_profile?.portal_data?.css_theme ?? "";
    }
  },
  watch: {
    "user.user_profile.portal_data": "restore",
    userCSSTheme: {
      handler(n) {
        this.css_theme = n;
      },
      immediate: true
    }
  },
  methods: {
    example(option) {
      return moment().format(option.value);
    },
    save() {
      let self = this;
      if (!this.isValid) return;
      this.busy = true;
      const oldTheme = this.userCSSTheme;
      this.$store
        .dispatch("user/updatePortalData", this.payload)
        .then((response) => {
          // It does not need to display any success toast, once it is going to reload the page, ;
          const newTheme = response?.user_profile?.portal_data?.css_theme || "";
          if (response && oldTheme != newTheme) {
            window.localStorage.setItem("css_theme", newTheme);
            document.location.reload();
          }
          this.busy = false;
          if (self.validateSaveResponse(response)) {
            self.showAlert(() => {
              this.$root.$emit("controlSidebar:setContent", null);
            });
          } else {
            self.showAlert();
          }
        });
    },
    restore(event) {
      let original = JSON.parse(
        JSON.stringify(this?.user?.user_profile?.portal_data || {})
      );
      this.removingTabs = [];
      if (
        event?.type == "click" &&
        original.defaultDashboardTab &&
        !this.parentTabs.some((t) => t.name == original.defaultDashboardTab)
      ) {
        original.defaultDashboardTab = this.$root.config.equipment_selection.tabs.initial;
      }
      this.$set(this, "portalData", original);
      this.portalDataUpdates++;
      this.autotz = original?.timezone || "" ? false : true;
    },
    removeTab(index) {
      if (typeof index == "number") {
        // cancel removing of tab in given index
        this.portalData.userTabs.push(this.removingTabs.splice(index, 1)[0]);
      } else {
        // add selected tab to removing list
        this.removingTabs.push(this.selectedTab);
        // if it is the default tab, replace it by the first one
        if (this.portalData.defaultDashboardTab == this.selectedTab.name) {
          this.$nextTick(() => {
            this.portalData.defaultDashboardTab =
              this.contractPortalData.defaultDashboardTab ??
              this.$root.config.equipment_selection.tabs.initial;
          });
        }
        // removes tab from portal data
        this.portalData.userTabs.splice(
          this.portalData.userTabs.findIndex(
            (t) => t.name == this.selectedTab.name
          ),
          1
        );
      }
    },
    canRemoveTab(tab) {
      // only allows removal of user tabs created by current user (aka. not contract level tabs)
      return this.portalData?.userTabs?.some?.((t) => t.name == tab.name);
    },
    resetTab() {
      this.$delete(this.portalData, "defaultDashboardTab");
    },
    isContractTabInUser(tab) {
      return this.portalData?.userTabs?.some?.(
        (userTab) => userTab.name == tab.name
      );
    },
    removeCustomTab(name) {
      if (!name) return;
      const ix = this.portalData.userTabs.findIndex((tab) => tab.name == name);
      if (ix >= 0) {
        this.portalData.userTabs.splice(ix, 1);
        this.$set(this, "portalData", this.portalData);
      }
    },
    toggleCustomTab(name) {
      if (!name) return;
      const ix = this.portalData.userTabs.findIndex((tab) => tab.name == name);
      if (ix >= 0) {
        this.portalData.userTabs[ix].enabled = !this.portalData.userTabs[ix]
          .enabled;
        this.$set(this, "portalData", this.portalData);
        this._contractConfig = true;
      }
    },
    enableCustomTabs() {
      let portalData = {...(this.portalData || {})};
      this.reversed = true;
      let userTabs = (portalData?.userTabs || []).filter(
        ({name}) => !(this.parentTabs || []).find((t) => t.name == name)
      );
      let tabs = structuredClone((this.parentTabs || []).concat(userTabs));
      if (!this.contractPortalData.customTabs) {
        tabs = tabs.reverse();
      }
      portalData.userTabs = tabs;
      portalData.defaultDashboardTab = this.defaultPortalData.defaultDashboardTab;
      portalData.custom_tabs = true;
      this.$set(this, "portalData", portalData);
    },
    disableStandardTabs() {
      let portalData = {...(this.portalData || {})};
      portalData.userTabs = null;
      portalData.defaultDashboardTab = null;
      portalData.custom_tabs = false;
      this.$set(this, "portalData", portalData);
      this.save();
    },
    onUserTabsClick() {
      this.customTabs
        ? (this.showCustomTabs = !this.showCustomTabs)
        : (this.customTabs = !this.customTabs);
    }
  },
  created() {
    this.service = new DashboardServices();
    this.restore();
  }
};
</script>

<style scoped>
.timezone-selector {
  font-size: 76%;
  line-height: 1em;
  padding: 0;
  max-height: 26px;
  margin-bottom: 15px;
}

.label-checkbox > input {
  vertical-align: top;
}

.clicable:hover {
  opacity: 0.8;
  cursor: pointer;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

.select-option {
  margin: 0 -12px;
}

.select-option > div.text-info {
  margin: -5px 5px 5px 5px;
}

#control-sidebar-0 .box-body > * {
  margin: 0.5em;
}

#control-sidebar-0 .box-body > *:not(:first-of-type) {
  margin-top: 1em;
}

.footer {
  margin-top: 1em;
}

.section {
  /* background-color: whitesmoke; */
}

.skin-dark .section {
  background-color: transparent;
}
</style>
