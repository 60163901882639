<template>
  <section v-if="portalData">
    <div class="list-group">
      <draggable v-model="tabs" handle=".list-group-item">
        <div
          class="list-group-item"
          v-for="item in tabs"
          :key="item.name"
          :class="{
            active: !editing && selected == item.name,
            editing: editing && item.name == prvEditing.name,
            'text-primary': editing && item.name == prvEditing.name,
            'list-group-item-disabled': !item.enabled
          }"
          :title="
            `${$t('drag_to_move')} ${$t('or').toLowerCase()} click ${$t(
              'for'
            ).toLowerCase()} ${$t('set_as_default').toLowerCase()}`
          "
          @click.stop.prevent="
            !editing && item.enabled && (selected = item.name)
          "
        >
          <div class="item-info">
            <Icon
              class="item-icon"
              :title="null"
              :name="
                (!item.iconClass.includes('fa ') &&
                item.iconClass.includes('fa-')
                  ? 'fa '
                  : '') + item.iconClass
              "
            />
            {{ $tc(item.title, 2) }}
            <i class="fa fa-pencil" @click.stop.prevent="editTab(item)"></i>
          </div>
          <div>
            <span
              class="btn btn-xs"
              :title="$t('visibility')"
              :disabled="selected == item.name"
              @click.stop.prevent="
                toggleCustomTab(selected != item.name ? item.name : '')
              "
            >
              <i :class="item.enabled ? 'fa fa-eye' : 'fa fa-eye-slash'"></i>
            </span>
            <span
              :title="`${$t('remove')} ${$tc(item.title, 2)}`"
              class="btn btn-xs"
              @click.stop.prevent="
                selected != item.name && removeCustomTab(item.name)
              "
              :disabled="selected == item.name"
            >
              <i class="fa fa-trash"></i>
            </span>
          </div>
        </div>
      </draggable>
    </div>
    <div class="text-center" style="margin-top:-10px;margin-bottom:20px">
      <div
        class="btn btn-xs btn-default"
        :title="$t('create_new_tab.title')"
        @click="createTabModalOpen = true"
        v-if="canCreateTab"
      >
        <i class="fa fa-plus"></i>
        {{ $t("add") }}
      </div>
      <div
        style="margin-left:10px"
        class="btn btn-xs btn-default"
        :title="$t('restore')"
        @click.stop.prevent="onRestore"
      >
        <i class="fa fa-undo"></i>
        {{ $t("restore") }}
      </div>
    </div>
    <portal to="modal" v-if="canCreateTab">
      <CreateDashboardTabModal
        :saveEnabled="false"
        context="user"
        :open.sync="createTabModalOpen"
        @tab:new="addTab"
      />
    </portal>
    <portal to="contextmenu">
      <FloatPanel
        class="float-panel float-panel-tab-editor"
        :draggable="true"
        :handleSelf="true"
        :defaultPosition="{top: floatPanel.top, left: floatPanel.left}"
        :open="floatPanel.open"
        :title="$t('edit')"
        @update:open="onFloatPanelUpdate($event)"
        @update:defaultPosition="onFloatPanelPosition($event)"
        @dragstart="floatPanel.dragging = true"
        @dragend="onDragEnd(floatPanel, $event)"
      >
        <div class="popup">
          <div class="popup-body">
            <div v-if="editing">
              <div class="form-group">
                <label for="">{{ $t("title") }}</label>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    v-model="editing.title"
                    :placeholder="prvEditing.title"
                    ref="inpTabTitle"
                    @click.stop
                    @keydown.stop
                  />
                  <div
                    class="input-group-addon btn"
                    @click.stop.prevent="
                      () => {
                        editing.title = prvEditing.title;
                        $refs.inpTabTitle.focus();
                      }
                    "
                  >
                    <i class="fa fa-undo"></i>
                  </div>
                  <div
                    class="input-group-addon btn"
                    @click.stop.prevent="
                      () => {
                        editing.title = '';
                        $refs.inpTabTitle.focus();
                      }
                    "
                  >
                    <i class="fa fa-close"></i>
                  </div>
                </div>
              </div>
              <IconSelector
                v-model="editingIconClass"
                :key="editingIconClass"
              />
            </div>
          </div>
        </div>
      </FloatPanel>
    </portal>
  </section>
</template>

<script>
import {isEqual} from "lodash";
import draggable from "vuedraggable";
import CreateDashboardTabModal from "@/components/create-dashboard-tab-modal";
import Icon from "@/components/icons/icon.vue";
import FloatPanel from "@/components/editor/float-panel.vue";
import IconSelector from "@/components/editor/icon-selector.vue";
const _fpsize = [500, 200];
let _fp = {
  h: _fpsize[1],
  w: _fpsize[0],
  y: parseInt((window.innerHeight - _fpsize[1]) / 2) - 150,
  x: parseInt((window.innerWidth - _fpsize[0]) / 2)
};
_fp.y = _fp.y < window.innerHeight ? _fp.y : parseInt(window.innerHeight * 0.8);
_fp.x = _fp.x < window.innerWidth ? _fp.x : parseInt(window.innerWidth * 0.8);
export default {
  name: "CustomTabsForm",
  components: {
    CreateDashboardTabModal,
    Icon,
    draggable,
    FloatPanel,
    IconSelector
  },
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data: () => {
    return {
      portalData: null,
      createTabModalOpen: false,
      draggable,
      floatPanel: {
        top: `${_fp.y}px`,
        left: `${_fp.x}px`,
        open: false,
        dragging: false
      },
      editing: null,
      prvEditing: null
    };
  },
  computed: {
    tabs: {
      set(value) {
        this.$set(this.portalData, "userTabs", value);
      },
      get() {
        return this?.portalData?.userTabs || [];
      }
    },
    selected: {
      set(value) {
        this.portalData.defaultDashboardTab = value;
      },
      get() {
        return this?.portalData?.defaultDashboardTab ?? "";
      }
    },
    canCreateTab() {
      return (
        this.$store.getters["user/loggedUser"].contract
          ?.allowed_custom_screens &&
        this.$can("manage", "TelaCustomizadaAcesso")
      );
    },
    isOpen() {
      return this?.floatPanel?.open || false;
    },
    editingIconClass: {
      set(value) {
        if (!this.editing) return;
        this.editing.iconClass = this.parseIconClass(value);
      },
      get() {
        if (!this.editing) return "";
        return this.parseIconClass(this.editing.iconClass);
      }
    }
  },
  watch: {
    portalData: {
      handler(n, o) {
        if (n && !isEqual(n, this.value)) {
          this.$emit("input", {...n});
        }
      },
      deep: true
    },
    // open(n, o) {
    //   if (!o && n) {
    //     this.floatPanel.open = !this.floatPanel.open;
    //     if (this.floatPanel.open) {
    //       // this.busy = true;
    //     }
    //   }
    // },
    isOpen: {
      handler(n) {
        if (!n) {
          this.editing = null;
          this.prvEditing = null;
        }
      },
      immediate: true
    }
  },
  methods: {
    removeCustomTab(name) {
      if (!name) return;
      const ix = this.portalData.userTabs.findIndex((tab) => tab.name == name);
      if (ix >= 0) {
        this.portalData.userTabs.splice(ix, 1);
      }
    },
    toggleCustomTab(name) {
      if (!name) return;
      const ix = this.portalData.userTabs.findIndex((tab) => tab.name == name);
      if (ix >= 0) {
        this.portalData.userTabs[ix].enabled = !this.portalData.userTabs[ix]
          .enabled;
      }
    },
    addTab(tab) {
      if (!tab) return;
      let portalData = this.portalData;
      portalData.userTabs = (portalData.userTabs || []).concat(tab);
      this.$set(this, "portalData", portalData);
    },
    onRestore() {
      this.$emit("restore");
    },
    onFloatPanelPosition($event) {
      if (this.floatPanel.open) {
        // this.busy = false;
        let $el = document.getElementsByClassName("float-panel-tab-editor");
        let h = $el && $el.length == 1 ? $el[0].clientHeight : _fp.h;
        let top = parseInt($event.top.replace(/\D+/, "") || 0);
        if (top <= document.documentElement.scrollTop) {
          top = h
            ? h / 2 + document.documentElement.scrollTop
            : document.documentElement.scrollTop;
        }
        this.floatPanel.top = `${top}px`;
        if (top + h >= window.innerHeight) {
          window.scrollTo(undefined, top);
        }
        // console.log(top);
      }
    },
    onFloatPanelUpdate(value) {
      if (this.floatPanel.open != value) {
        this.floatPanel.open = value;
      }
      if (this.floatPanel.open) {
        // this.busy = true;
      }
    },
    editTab(tab) {
      if (!tab) return;
      if (!this.editing || this.editing.name == tab.name) {
        this.floatPanel.open = !this.floatPanel.open;
      }
      if (this.floatPanel.open) {
        this.prvEditing = structuredClone(tab);
        this.editing = tab;
      } else {
        this.prvEditing = null;
        this.editing = null;
      }
    },
    onDragEnd(floatPanel, $event) {
      floatPanel.top = $event.top;
      floatPanel.left = $event.left;
      floatPanel.dragging = false;
    },
    parseIconClass(iconClass) {
      let value = iconClass.includes("fa-")
        ? `fa ${iconClass}`
        : iconClass.includes("glyphicon-")
        ? `glyphicon ${iconClass}`
        : iconClass;
      return this.$utils.distinct(this.$utils.trim(value).split(" ")).join(" ");
    }
  },
  created() {
    this.$set(this, "portalData", structuredClone(this.value));
  }
};
</script>

<style scoped>
.list-group-item {
  padding: 5px;
  display: flex;
  justify-content: space-between;
  cursor: move;
}
.list-group-item-disabled {
  opacity: 0.5;
}

.item-info:hover {
  cursor: pointer;
}

.item-info > i.fa-pencil {
  opacity: 0;
  text-align: center;
  min-width: 20px;
  margin-right: 2px;
  transition: opacity ease 0.35s;
}

.item-icon {
  text-align: center;
  min-width: 20px;
  margin-right: 2px;
}

.item-info:hover > i.fa-pencil {
  opacity: 1;
}

.list-group-item.editing > .item-info > i.fa-pencil {
  opacity: 1;
}

.popup {
  position: relative;
}

.popup > .popup-body {
  padding: 10px;
  width: 500px;
  min-width: 500px;
  min-height: 200px;
  max-height: 60vh;
  resize: both;
  overflow: hidden;
  border-top: 1px solid lightgray;
}

.popup > .popup-body > div {
  width: 100%;
}
</style>
