<template>
  <section>
    <form role="form">
      <div class="row">
        <div class="form-group col-xs-6">
          <div class="input-group">
            <div class="input-group-addon">{{ $t("name") }}</div>
            <input
              class="form-control"
              v-model="entry.name"
              ref="entry_name"
              v-bind:placeholder="$t('text_list_name')"
              v-bind:disabled="isModelBased"
            />
          </div>
        </div>
        <div class="form-group col-xs-3 text-left">
          <button
            class="btn btn-default btn-gap"
            v-bind:title="$t('undo')"
            v-bind:disabled="busy || isModelBased"
            v-on:click.prevent.stop="undo"
          >
            <i class="fa fa-undo"></i>
          </button>

          <button
            v-if="entry && entry.source && entry.source.id"
            class="btn btn-default btn-gap"
            v-bind:title="$t('delete')"
            v-bind:disabled="busy || isModelBased"
            v-on:click.prevent.stop="remove"
          >
            <i class="fa fa-trash"></i>
          </button>

          <button
            class="btn btn-default"
            v-bind:title="$t('save')"
            v-bind:disabled="busy || isModelBased"
            v-on:click.prevent.stop="close"
            v-if="autosave"
          >
            <i class="fa fa-close"></i>
          </button>

          <button
            v-else
            class="btn btn-primary btn-gap"
            v-bind:title="$t('save')"
            v-bind:disabled="!isValid || busy || isModelBased"
            v-on:click.prevent.stop="save"
          >
            <i class="fa fa-refresh fa-spin" v-if="busy"></i>
            <i class="fa fa-check" v-else></i>
          </button>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-8">
          <div class="input-group">
            <div class="input-group-addon btn">
              <input
                type="checkbox"
                v-model="all"
                v-bind:style="{
                  visibility: !entry.items.length ? 'hidden' : 'initial'
                }"
              />
            </div>
            <div class="input-group-addon value-addon">
              <div class="value-input">
                <strong>{{ $t("value") }}</strong>
              </div>
            </div>
            <div class="form-control text-center">
              <strong>{{ $t("text") }}</strong>
            </div>
            <div
              class="input-group-addon btn"
              v-if="checked_list.length"
              v-on:click.stop.prevent="del"
              :title="$t('remove_selected_ones')"
            >
              <i class="fa fa-eraser toolbar-button"></i>
            </div>
          </div>

          <div
            class="input-group"
            v-for="(item, ix) in entry.items"
            v-bind:key="ix"
          >
            <div class="input-group-addon">
              <input type="checkbox" v-model="item.checked" />
            </div>
            <div class="input-group-addon value-addon">
              <input
                class="value-input"
                v-model="item.id"
                v-bind:class="{'input-error': inError(ix)}"
              />
            </div>
            <input
              type="text"
              class="form-control"
              v-model="item.text"
              v-bind:class="{'input-error': inError(ix)}"
            />
            <div
              class="input-group-addon btn"
              v-on:click.stop.prevent="entry.default_id = item.id"
            >
              <i
                class="fa fa-check icon-default"
                v-bind:class="{
                  'text-success': entry.default_id == item.id,
                  'text-gray': entry.default_id != item.id
                }"
                v-bind:title="$t('set_as_default')"
              >
              </i>
            </div>
          </div>

          <div class="input-group">
            <div class="input-group-addon disabled">
              <i class="fa fa-pencil disabled icon-edit" style=""></i>
            </div>
            <div class="input-group-addon value-addon">
              <input
                class="value-input"
                type="text"
                v-model="new_item.id"
                ref="new_id"
                v-bind:placeholder="$t('value')"
                v-on:keydown.enter.prevent.stop="add"
              />
            </div>
            <input
              class="form-control"
              type="text"
              v-model="new_item.text"
              v-bind:placeholder="$t('new_text_value')"
              v-on:keydown.enter.prevent.stop="add"
              v-on:keydown.tab.prevent.stop="add"
            />
            <div
              class="input-group-addon btn fa-btn"
              v-bind:disabled="!isNewItemValid"
              v-on:click.prevent.stop="add"
            >
              <i class="fa fa-plus"></i>
            </div>
          </div>
        </div>
      </div>
    </form>
  </section>
</template>

<script>
import TextListService from "@/services/text-list.js";
function defaultItem() {
  return {id: "", text: "", checked: false, error: false};
}

export default {
  name: "FormTextList",
  props: {
    text_list: {
      type: Object,
      default: () => null
    },
    autosave: {
      type: Boolean,
      default: () => false
    },
    isModelBased: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  data() {
    return {
      busy: false,
      entry: null,
      all: false,
      new_item: defaultItem(),
      etag: "",
      id: ""
    };
  },
  computed: {
    isValid() {
      return (this.entry.name && this.entry.items.length) || false;
    },
    isNewItemValid() {
      return this.new_item.id && this.new_item.text;
    },
    checked_list() {
      return (this.entry.items || []).filter(function(i) {
        return i.checked;
      });
    },
    errors() {
      let ids = this.entry.items.map(function(i) {
        return i.id + "";
      });
      let inserted = {};
      let errors = {};
      let items = this.entry.items.concat([this.new_item]);
      for (var i in items) {
        var key = items[i].id + "";
        if (key in inserted) {
          errors[i] = inserted[key];
        } else {
          inserted[key] = i;
        }
      }
      return errors;
    },
    payload() {
      let self = this;
      let items = {};
      let default_item = null;
      for (let i in this.entry.items) {
        items[this.entry.items[i].id] = this.entry.items[i].text;
        if (this.entry.items[i].id == this.entry.default_id) {
          default_item = {};
          default_item[this.entry.items[i].id] = this.entry.items[i].text;
        }
      }
      let user = this.$store.getters["user/loggedUser"];
      let payload = {
        name: this.entry.name,
        default_item: default_item,
        contract_id: (user && user.contract_id) || 1,
        items: items
      };
      if (self.id) {
        payload.id = self.id;
      }
      var etag =
        this.etag ||
        (this.entry && this.entry.source && this.entry.source.etag) ||
        "";
      if (etag) {
        payload.etag = etag;
      }
      if (self.entry.source) {
        payload.portal_data = this.entry.source.portal_data || {};
      }
      return payload;
    },
    canUndo() {
      return (
        this.etag &&
        this.entry.source &&
        this.entry.source.etag &&
        this.etag != this.entry.source.etag
      );
    }
  },
  watch: {
    entry: {
      handler(n, o) {
        let self = this;
        if (n && o) {
          if (!self._timerSave && self.autosave) {
            self._timerSave = setTimeout(function() {
              self._timerSave = null;
              if (self.isValid) {
                self.service.save(self.payload).then((response) => {
                  if (response && response.etag) {
                    self.id = response.id || 0;
                    self.etag = response.etag;
                    if (response) {
                      self.$emit("update", {id: self.id});
                    }
                  }
                });
              }
            }, 200);
          }
        }
      },
      deep: true
    },
    all(n) {
      let items = JSON.parse(JSON.stringify(this.entry.items || [])).map(
        function(i) {
          i.checked = n;
          return i;
        }
      );
      this.$set(this.entry, "items", items);
    }
  },
  methods: {
    inError(position) {
      let errors = this.errors;
      for (var i in errors) {
        if (errors[i] == position) {
          return true;
        }
      }
      return false;
    },
    add() {
      if (!this.isNewItemValid) return;
      this.entry.items.push({
        id: this.new_item.id,
        text: this.new_item.text,
        checked: false
      });
      if (this.entry.items.length == 1) {
        this.entry.default_id = this.new_item.id;
      }
      this.new_item = defaultItem();
      this.focus();
    },
    del() {
      let self = this;
      let items = JSON.parse(JSON.stringify(this.entry.items || [])).filter(
        function(i) {
          return !i.checked;
        }
      );
      this.$set(this.entry, "items", items);
      if (!items.length) {
        self.all = false;
      }
      let default_lst = items.filter(function(i) {
        return i.id == self.entry.default_id;
      });
      if (!default_lst.length && items.length) {
        self.entry.default_id = items[0].id;
      } else {
        self.entry.default_id = "";
      }
      self.focus();
    },
    focus() {
      let self = this;
      this.$nextTick(function() {
        if (self.entry && !self.entry.name) {
          self.$refs.entry_name.focus();
        } else {
          self.$refs.new_id.focus();
        }
      });
    },
    undo() {
      let self = this;
      if (
        self.autosave &&
        self.entry &&
        self.entry.source &&
        self.entry.source.id
      ) {
        if (self.busy) return;
        if (this.canUndo) {
          this.initData();
          this.focus();
          return;
        }
      }
      this.$emit("close");
    },
    close() {
      let self = this;
      if (self.busy) return;
      if (this.autosave && this.isValid) {
        this.save();
      } else {
        this.$emit("close");
      }
    },
    save() {
      let self = this;
      if (self.busy) return;
      if (self.isValid) {
        if (self.isNewItemValid) {
          self.add();
        }
        self.busy = true;
        let payload = this.payload;
        self.service.save(payload).then((response) => {
          self.busy = false;
          if (response) {
            self.id = response.id || 0;
            self.$emit("save", {id: self.id});
          }
        });
        return;
      }
    },
    remove() {
      let self = this;
      if (self.busy) return;
      if (self.entry && self.entry.source && self.entry.source.id) {
        self
          .$swal({
            title: self.$t("are_you_sure"),
            text: self.$t("you_wont_be_able_to_revert_this"),
            icon: "warning",
            buttons: [self.$t("cancel"), self.$t("yes_delete_it")]
          })
          .then((isConfirm) => {
            if (isConfirm) {
              self.busy = true;
              let payload = self.payload;
              this.service.remove(payload).then((response) => {
                self.busy = false;
                if (response) {
                  self.$emit("remove");
                }
              });
            }
          });
      }
      return;
    },
    initData() {
      let entry = null;
      if (this.text_list) {
        entry = JSON.parse(JSON.stringify(this.text_list));
        for (var i in entry.items || []) {
          entry.items[i].checked = false;
        }
      } else {
        entry = {
          default_id: 0,
          id: 0,
          name: "",
          items: []
        };
      }
      this.$set(this, "new_item", defaultItem());
      this.$set(this, "entry", entry);
    }
  },
  created() {
    this._timerSave = null;
    this.service = new TextListService();
    this.etag =
      (this.text_list && this.text_list.source && this.text_list.source.etag) ||
      "";
    this.id =
      (this.text_list && this.text_list.source && this.text_list.source.id) ||
      0;
    this.initData();
  },
  mounted() {
    this.focus();
  }
};
</script>

<style scoped>
.padding-1 {
  padding: 1px;
}
.toolbar-button {
  margin: 0 5px;
}
.value-addon {
  padding: 0;
  width: 100px;
}
.fa-btn:hover {
  color: #3c8dbc;
  cursor: pointer;
}
.value-addon-disabled {
  background-color: whitesmoke;
}
.value-input {
  width: 90%;
  padding: 0;
  border: 0;
  outline-width: 0;
  text-align: center;
}
.skin-dark .value-addon {
  background-color: var(--skin-dark-darker);
}
.skin-dark .value-input {
  background-color: var(--skin-dark-darker);
}
.value-input:focus,
.value-input:active {
  border: 0;
  outline-width: 0;
}
.icon-edit {
  color: darkgrey;
  padding-left: 1px;
}
.input-error {
  color: tomato;
}
.btn-gap {
  margin-right: 5px;
}
.icon-default {
  margin: 0 -1px 0 -2px;
}
</style>
