<template>
  <Box class="statistics-box-container" type="primary" solid>
    <h4>{{ title }} <Tooltip v-if="hint" :title="hint" class="fa fa-info-circle" /></h4>
    <slot></slot>
    <template #table v-if="$slots.table">
      <slot name="table"></slot>
    </template>
  </Box>
</template>

<script>
import Box from "@/components/widgets/box";
import Tooltip from "@/components/tooltip";
export default {
  name: "StatisticsBox",
  components: {
    Box,
    Tooltip
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    hint: {
      type: String,
      default: ""
    }
  }
};
</script>

<style scoped>
h4 {
  margin: 0;
  font-weight: 600;
}

.box.box-primary.box-solid {
  border-top: 5px solid #3c8dbc;
}

.box::v-deep .box-body {
  height: 100%;
}
</style>
<style>
.statistics-box-container {
  color: #666;
}
.skin-dark .box.box-solid.box-primary {
  border: 1px solid var(--skin-dark-dark);
}
.skin-dark .box.box-primary.box-solid {
  border-top: 5px solid var(--skin-dark-dark);
}
</style>
